

































































































































































































































































































































import DialogMixins from '@/mixins/DialogMixins';
import WidgetMixins from '@/mixins/WidgetMixins';
import { loadWidget } from '@/utils/helpers';
import Component, { mixins } from 'vue-class-component';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max, numeric } from 'vee-validate/dist/rules';
import { Watch } from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import {
  IContact,
  IGroup,
  IMessage,
  ISenderID,
  ISubAccount,
} from '@/types/types';

const groupModule = namespace('group');
const contactModule = namespace('contact');
const smsModule = namespace('sms');

extend('required', {
  ...required,
  message: `Field is required`,
});
extend('numeric', {
  ...numeric,
  message: `Field must contain only numbers`,
});
extend('max', {
  ...max,
  message: `Field cannot have more than 11 characters`,
});

@Component({
  name: 'QuickSMS',
  components: {
    InternetConnection: loadWidget('pages/InternetConnection'),
    ToolBar: loadWidget('widgets/ToolBar'),
    SnackBar: loadWidget('widgets/SnackBar'),
    EmptyPage: loadWidget('pages/EmptyPage'),
    ProgressBar: loadWidget('widgets/CircularProgressLoader'),
    BulkUploadDialog: loadWidget('widgets/BulkUpload'),
    AddSMSSenderID: loadWidget(`widgets/AddSenderID`),
    ValidationProvider,
    ValidationObserver,
  },
})
export default class QuickSMS extends mixins(WidgetMixins, DialogMixins) {
  @Getter('getCreditBalance') creditBalance!: number;
  @groupModule.Getter('getAllGroups') groups!: IGroup[];
  @Getter('getResetFormState') resetFormState!: boolean;
  @contactModule.Getter('getAllContacts') contacts!: IContact[];
  @smsModule.Getter('getSenderIDList') smsIDList!: Array<
    ISenderID & { uuid: string; id: number; slug: string }
  >;

  smsType: string[] = ['Quick', 'Flash'];
  sms: IMessage[] = [];
  isContactsLoading = false;
  currentIndex = 0;
  totalCreditToBeUsed = 0;
  totalMessages = 0;
  totalContactList = 0;

  subAccount: ISubAccount = {
    payload: null,
    id: null,
  };

  @Watch('contacts')
  onContactChange(payload: IContact[]): void {
    this.isContactsLoading = true;

    setTimeout(() => {
      this.sms[this.currentIndex].to = payload
        .map((contact) => contact.phone)
        .join(',');
      this.isContactsLoading = false;
    }, 2000);
  }

  heading: IMessage = {
    to: '',
    from: '',
    type: 'Quick',
    message: '',
  };

  addRow(): void {
    this.sms.push({ ...this.heading });
  }
  removeRow(index: number): void {
    this.sms.splice(index, 1);
  }

  bulkUpload(file: File): void {
    let formData = new FormData();
    formData.append('file', file, file.name);
    this.$store.dispatch('contact/processContactsFile', formData);
  }

  // @Watch('resetFormState')
  // onFormChange(payload: boolean): void {
  //   if (payload) {
  //     // this.sms = [];
  //     console.log(payload);
  //   } else this.$store.dispatch('resetFormValues', false, { root: true });
  // }

  openUploadDialog(index: number): void {
    this.open('upload');
    this.currentIndex = index;
  }

  @Watch('sms', {
    deep: true,
  })
  onBulkSMSContentChange(payload: Array<IMessage>): void {
    this.totalMessages = payload
      .map((event) => event.message.length)
      .reduce((acc, cur) => (acc += cur), 0);
    this.totalContactList = payload
      .map((event) => event.to.split(',').length)
      .reduce((acc, cur) => (acc += cur), 0);
    this.totalCreditToBeUsed = payload
      .map((event) => {
        return (
          Math.ceil(event.message.length / 160) * event.to?.split(',').length ??
          1
        );
      })
      .reduce((acc, cur) => (acc += cur), 0);
  }

  sendSMS(): void {
    this.$store.dispatch('sms/sendBulkSMS', {
      body: {
        messages: this.sms[0].to.split(',').map((msisdn) => {
          return {
            to: msisdn,
            message: this.sms[0].message,
            from: this.heading.from,
            type: this.heading.type,
          };
        }),
      },
      ...(this.subAccount.id && {
        'x-subaccount-id': this.subAccount.id,
      }),
    });
  }

  created(): void {
    this.$store.dispatch('group/rawList');
    this.$store.dispatch('checkCreditBalance');
  }
}
